#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

#footer a {
  text-decoration: none;
  color: #61dafb;
}
