.App {
  text-align: center;
  background-color: #181818;
}
.input {
  border-color: white;
  border-radius: 12px;
  background-color: white;
  height: 48px;
  max-width: 80%;
  width: 300px;
  padding-left: 12px;
  padding-right: 12px;
}

.input-button {
  height: 48px;
  max-width: 15%;
  width: 100px;
  margin-left: 20px;
  background-color: #86e3fd;
  color: black;
}

.top {
  margin-bottom: 24px;
}
